import * as styles from './Flexbox.module.css';
export default function Flexbox({ children, margin, reverse, center, wrap, even, padding }) {
  return (
    <div
      className={[
        styles.Flexbox,
        margin && styles.margin,
        padding && styles.padding,
        reverse && styles.reverse,
        center && styles.center,
        wrap && styles.wrap,
        even && styles.even
      ].join(" ")}
    >
      {children}
    </div>
  );
}
