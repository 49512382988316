import { graphql, useStaticQuery } from 'gatsby';

import Flexbox from '@/components/Flexbox'
import Form from '@/components/Form';
import Layout from '@/containers/layout';
import Page from '@/components/Page'
import SEO from '../components/SEO';
import { useLocation } from '@reach/router'

const ContactPage = props => {
    const queryResult = useStaticQuery(graphql`
  query quotePageQuery {
      allMarkdownRemark(filter: {frontmatter: {type: {eq: "page"}}}) {
        nodes {
          frontmatter {
              title
              content {
                header
                text
              }
            seo {
              description
              title
              previewImage {
                childImageSharp {
                  original {
                    height
                    width
                  }
                }
                publicURL
              }
            }
          }
        }
      }
    }      
  `)


    const data = queryResult.allMarkdownRemark.nodes.filter(el => el.frontmatter.title === 'Request a Quote')[0]
    let seoData = data.frontmatter.seo
    seoData.canonicalURL = useLocation();


    return (
        <Layout>
            <SEO data={seoData} />
            <Page paddingtop>
                <Flexbox even>
                    <div >
                        <h1 className='marginBottom'>{data.frontmatter.title}</h1>
                        <br />
                        <p>{data.frontmatter.content[0].text}</p>
                    </div>
                    <div>
                        <Form />
                    </div>

                </Flexbox>
            </Page>
        </Layout>
    )
}

export default ContactPage;